export default class Popup {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.closeEl = this.root.querySelector('[data-popup-close]');

        return true;
    }

    init() {
        this.setPopup();
    }

    setPopup() {
        this.bindClose();
    }

    bindClose() {
        if (!this.closeEl) return;
        this.closeEl.addEventListener('click', this.closePopup.bind(this));
    }

    closePopup() {
        this.root.remove();
        this.checkGalleryItems();
        document.documentElement.style.overflowY = '';
    }

    checkGalleryItems() {
        const galleryItems = document.querySelectorAll('.galleries-grid__item');
        const galleryTabsItem = document.querySelectorAll(
            '.gallery-tabs-gallery__item'
        );

        galleryItems.forEach(item => {
            item.style.pointerEvents = 'all';
        });
        galleryTabsItem.forEach(item => {
            item.style.pointerEvents = 'all';
        });
    }
}
